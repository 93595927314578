import Vue from "vue";
var vm = new Vue();

import statics from "@/core/statics/statics.js";

// Create a prototype to format a striong w/ a regex
// First, checks if it isn't implemented yet.
if (!String.prototype.format) {
  String.prototype.format = function () {
    var args = arguments;
    return this.replace(/{(\d+)}/g, function (match, number) {
      return typeof args[number] != "undefined" ? args[number] : match;
    });
  };
}

// https://forum.vuejs.org/t/how-to-use-helper-functions-for-imported-modules-in-vuejs-vue-template/6266/5
// export default {
//   methods: {
//     formatNumber: function(number, precision = 2) {
//       return accounting.formatNumber(number, { precision: precision });
//     }
//   }
// };
//<template>
//  <p>{{formatNumber(total, 0)}}</p>
//</template>
//<script>
//import mixins from './mixins.js'
//export default {
//  name: 'Example',
//  mixins: [mixins]
//}
//</script>

export default {
  methods: {
    // Main
    getStatics: function () {
      return statics;
    },
    // Operations
    getFlowType: function (key, label) {
      console.log("getFlowType::", key, label, typeof key, typeof label);
      // @Todo make a notnull fct
      if (key !== "" && key !== null && typeof key !== "undefined" && label !== "" && label !== null && typeof label !== "undefined")
        return statics.flowTypes.filter((t) => t.id == key)[0][label];
      else return [];
    },
    getStatusType: function (key, label) {
      console.log("getStatusType::", key, label, typeof key, typeof label);
      // @Todo make a notnull fct
      if (key !== "" && key !== null && typeof key !== "undefined" && label !== "" && label !== null && typeof label !== "undefined")
        return statics.statusTypes.filter((t) => t.id == key)[0][label];
      else return "-"; // Returning a string instead of an array
    },
    getThirdPartyType: function (key, label) {
      console.log("getThirdPartyType::", key, label, typeof key, typeof label);
      // @Todo make a notnull fct
      if (key !== "" && key !== null && typeof key !== "undefined" && label !== "" && label !== null && typeof label !== "undefined")
        return statics.thirdPartyTypes.filter((t) => t.id == key)[0][label];
      else return [];
    },
    getOperationThirdPartyDetails_refactored: function (operation, pov = "me", labels = "Name", returns = "") {
      // returns = what to return if no results

      // Refactored function from v6 / vue2 version

      // Define
      let flowType = operation.OperationType;
      let MyRole = operation.MyRole;
      let Partners = operation.Partners;
      let HisRole = operation.HisRole;

      // Console
      console.log(
        "getOperationThirdPartyDetails_refactored::",
        pov,
        labels,
        "flowType / OperationType::",
        operation.OperationType,
        "MyRole::",
        operation.MyRole,
        "HisRole::",
        operation.HisRole,
        "Partners::",
        operation.Partners,
        typeof operation.Partners,
        Object.keys(operation.Partners).length,
        operation.Partners.length,
        "Process or return ?",
        typeof flowType !== "undefined" && typeof MyRole !== "undefined" && typeof Partners !== "undefined" && Object.keys(Partners).length > 0
      );

      // Refactored
      if (typeof flowType !== "undefined" && typeof MyRole !== "undefined" && typeof Partners !== "undefined" && Object.keys(Partners).length > 0) {
        console.log("getOperationThirdPartyDetails_refactored::Process");
        // First retrieves the allowed thirdparty types for this flow
        let allowedThirdPartyType = this.getAllowedThirdPartyType(flowType, MyRole, pov, HisRole); // Added this HisRole
        console.log("getOperationThirdPartyDetails_refactored::allowedThirdPartyType?", allowedThirdPartyType);

        // Abord if we don't find a allowedThirdPartyType
        if (typeof allowedThirdPartyType === "undefined") return returns;

        // Finally find the partner
        let allowedPartners = Partners.filter((p) => allowedThirdPartyType.includes(p.PartnerRole)); //[Vue warn]: Error in render: "TypeError: Partners.filter is not a function"
        console.log("getOperationThirdPartyDetails_refactored::allowedPartners", allowedPartners);

        if (allowedPartners.length == 0) return returns;

        var ret = [];
        labels = labels.split(",");

        // And return the label(s) we are looking to return
        allowedPartners.forEach((p) => {
          let Partner = this.getPartnerByPartnerID(p.PartnerID);
          console.log("getOperationThirdPartyDetails_refactored::allowedPartners:Partner", p.PartnerID, Partner);
          if (p.PartnerID !== 0 && typeof Partner !== "undefined")
            labels.forEach((label) => {
              console.log("getOperationThirdPartyDetails_refactored::allowedPartners:Partner:label", label, typeof Partner.Attributes);
              if (label === "isClient" && typeof Partner.Attributes !== "undefined") Partner.Attributes.includes("isClient") ? ret.push("isClient") : returns;
              else ret.push(Partner[label]);
            });
          else if (p.PartnerID === 0) ret.push("( " + p.PartnerFullName + " )");
        });

        // Return strings
        return ret[0] !== undefined ? ret.join(", ") : returns;
      } else return returns;
    },
    getOperationThirdPartyDetails: function (flowType, MyRole, Partners, pov = "me", labels = "Name", returns = "", HisRole = "") {
      // returns = what to return if no results
      console.log(
        "getOperationThirdPartyDetails::",
        flowType,
        MyRole,
        Partners,
        pov,
        labels,
        typeof Partners,
        Object.keys(Partners).length,
        Partners.length,
        "HisRole::",
        HisRole
      );
      if (typeof flowType !== "undefined" && typeof MyRole !== "undefined" && typeof Partners !== "undefined" && Object.keys(Partners).length > 0) {
        console.log("getOperationThirdPartyDetails::ENTER ?");
        // First retrieves the allowed thirdparty types for this flow
        let allowedThirdPartyType = this.getAllowedThirdPartyType(flowType, MyRole, pov, HisRole); // Added this HisRole
        console.log("getOperationThirdPartyDetails::allowedThirdPartyType?", allowedThirdPartyType);

        // Abord if we don't find a allowedThirdPartyType
        if (typeof allowedThirdPartyType === "undefined") return returns;

        // Finally find the partner
        let allowedPartners = Partners.filter((p) => allowedThirdPartyType.includes(p.PartnerRole)); //[Vue warn]: Error in render: "TypeError: Partners.filter is not a function"
        console.log("getOperationThirdPartyDetails::allowedPartners", allowedPartners);

        if (allowedPartners.length == 0) return returns;

        var ret = [];
        labels = labels.split(",");

        // And return the label(s) we are looking to return
        allowedPartners.forEach((p) => {
          let Partner = this.getPartnerByPartnerID(p.PartnerID);
          console.log("getOperationThirdPartyDetails::allowedPartners:Partner", p.PartnerID, Partner);
          if (p.PartnerID !== 0 && typeof Partner !== "undefined")
            labels.forEach((label) => {
              console.log("getOperationThirdPartyDetails::allowedPartners:Partner:label", label, typeof Partner.Attributes);
              if (label === "isClient" && typeof Partner.Attributes !== "undefined") Partner.Attributes.includes("isClient") ? ret.push("isClient") : returns;
              else ret.push(Partner[label]);
            });
          else if (p.PartnerID === 0) ret.push("( " + p.PartnerFullName + " )");
        });

        // Return strings
        return ret[0] !== undefined ? ret.join(", ") : returns;
      } else return returns;
    },
    getOperationPOVDetails(flowType, MyRole, POVs, pov = "me", labels = "Name", returns = [], HisRole = "") {
      // returns = what to return if no results
      console.log("getOperationPOVDetails::", flowType, MyRole, POVs, pov, labels);
      if (typeof flowType !== "undefined" && typeof MyRole !== "undefined" && typeof POVs !== "undefined" && POVs !== null) {
        // First retrieves the allowed thirdparty types for this flow
        let allowedThirdPartyType = this.getAllowedThirdPartyType(flowType, MyRole, pov, HisRole); // Added this HisRole

        // Abord if we don't find a allowedThirdPartyType
        if (typeof allowedThirdPartyType === "undefined") return returns;

        // Finally find the partner
        let allowedPOV = POVs.filter((p) => allowedThirdPartyType.includes(p.Role));

        var ret = [];
        labels = labels.split(",");

        // And return the label(s) we are looking to return
        allowedPOV.forEach((p) => {
          console.log("getOperationPOVDetails::allowedPOV", p);

          labels.forEach((label) => {
            ret.push(p[label]);
            console.log("getOperationPOVDetails::allowedPOV::Label", label);
          });
        });

        // Return arrays
        console.log("getOperationPOVDetails::", ret);
        return ret;
      } else return returns;
    },
    getAllowedThirdPartyType: function (flowType, MyRole, pov, HisRole = "") {
      console.log("getAllowedThirdPartyType::", flowType, MyRole, pov, HisRole);

      // Retrieves the allowed thirdparty types for this flow
      let allowedThirdPartyType = this.getFlowType(flowType, "allowedThirdPartyType");

      // New v6 pov case
      // Case depends to HisRole override pov (AD)
      // when HisRole is defined ( normally always will be ) : if i'm looking for me = MyRole, if i'm looking for thirdparty = HisRole
      // if (HisRole != "" && (pov == "me" || pov == "thirdparty"))
      //   switch (pov) {
      //     case "me":
      //       allowedThirdPartyType = [MyRole];
      //       break;
      //     case "thirdparty":
      //       allowedThirdPartyType = [HisRole]; // If we got HisRole ( basically new v6 approach ) allowedThirdPartyType is defined by HisRole
      //       break;
      //   }

      // ! FALLBACK ! Regular v4 pov case // @TODO to remove in vue3 refactoring warning : used in ThirdPartiesPanel without HisRole
      // Then pick one or many depends the POV : we want my role or we want the counterpart ?
      // if (HisRole == "")
      switch (pov) {
        case "me":
          allowedThirdPartyType = HisRole != "" ? (allowedThirdPartyType = [MyRole]) : allowedThirdPartyType.filter((a) => a === MyRole);
          break;
        case "thirdparty":
          allowedThirdPartyType = HisRole != "" ? (allowedThirdPartyType = [HisRole]) : allowedThirdPartyType.filter((a) => a !== MyRole);
          break;
        case "shipper":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "MC");
          allowedThirdPartyType = ["MC"];
          break;
        case "carrier":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "MT");
          allowedThirdPartyType = ["MT"];
          break;
        case "dock":
          allowedThirdPartyType = ["AD"];
          break;
        case "receiver":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "MD");
          allowedThirdPartyType = ["MD"];
          break;
        case "notified":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "NN");
          allowedThirdPartyType = ["NN"];
          break;
        case "subshipper":
          allowedThirdPartyType = ["SC"];
          break;
        case "subcarrier":
          allowedThirdPartyType = ["ST"];
          break;
        case "subreceiver":
          allowedThirdPartyType = ["SD"];
          break;
        case "all":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "NN");
          allowedThirdPartyType = ["MC", "MT", "AD", "MD", "SC", "ST", "SD", "NN"];
          break;
      }

      // Finaly return
      if (typeof allowedThirdPartyType !== "undefined") return allowedThirdPartyType;
      else return [];
    },
    getAllowedSubThirdPartyType: function (flowType, MyRole, pov) {
      console.log("getAllowedSubThirdPartyType::", flowType, MyRole, pov);

      // Retrieves the allowed thirdparty types for this flow
      let allowedThirdPartyType = this.getFlowType(flowType, "allowedThirdPartyType");

      // Then pick one or many depends the POV : we want my role or we want the counterpart ?
      switch (pov) {
        case "me":
          allowedThirdPartyType = allowedThirdPartyType.filter((a) => a === MyRole);
          break;
        case "thirdparty":
          allowedThirdPartyType = allowedThirdPartyType.filter((a) => a !== MyRole);
          break;
        case "shipper":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "SC");
          allowedThirdPartyType = ["SC"];
          break;
        case "carrier":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "ST");
          allowedThirdPartyType = ["ST"];
          break;
        case "receiver":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "SD");
          allowedThirdPartyType = ["SD"];
          break;
        // case "notified":
        //   //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "NN");
        //   allowedThirdPartyType = ["NN"];
        //   break;
        case "all":
          //allowedThirdPartyType = allowedThirdPartyType.filter(a => a === "NN");
          allowedThirdPartyType = ["MC", "MT", "MD", "SC", "ST", "SD", "NN"];
          break;
      }

      // Finaly return
      if (typeof allowedThirdPartyType !== "undefined") return allowedThirdPartyType;
      else return [];
    },
    getImpactAccount: function (flowType, MyRole, HisRole, FP, FR) {
      console.log("getImpactAccount::", flowType, MyRole, typeof MyRole, HisRole, typeof HisRole, FP, FR);
      if (typeof MyRole === "object") MyRole = MyRole[0];
      if (typeof FR === "undefined") FR = 0;
      console.log(
        "getImpactAccount::",
        flowType,
        MyRole,
        HisRole,
        FP,
        FR,
        this.getDebtDirection(flowType, MyRole, HisRole),
        (FP - FR) * this.getDebtDirection(flowType, MyRole, HisRole)
      );
      if (typeof MyRole !== "undefined" && typeof FP !== "undefined" && typeof FR !== "undefined")
        return FP - FR !== 0 ? (FP - FR) * this.getDebtDirection(flowType, MyRole, HisRole) : FP - FR;
      // Avoid -0 issue
      else return "—";
    },
    getDebtDirection: function (flowType, MyRole, HisRole) {
      let DebtDirection = 0;
      switch (flowType) {
        case "C":
          if (MyRole == "MC") DebtDirection = 1;
          if (MyRole == "MT" && HisRole == "MC") DebtDirection = -1;
          if (MyRole == "MT" && HisRole == "ST") DebtDirection = 1;
          if (MyRole == "ST") DebtDirection = -1;
          break;
        case "D":
          if (MyRole == "MD") DebtDirection = -1;
          if (MyRole == "MT" && HisRole == "MD") DebtDirection = 1;
          if (MyRole == "MT" && HisRole == "ST") DebtDirection = -1;
          if (MyRole == "ST") DebtDirection = 1;
          break;
        case "T":
          if (MyRole == "MC") DebtDirection = 1;
          if (MyRole == "MD") DebtDirection = -1;
          break;
        default:
          DebtDirection = 0;
      }
      return DebtDirection;
    },
    getTimelineType: function (key, label) {
      if (key !== null && typeof key !== "undefined" && label !== null && typeof label !== "undefined")
        return statics.timelineTypes.filter((i) => i.id == key)[0][label];
      else return [];
    },
    getFilterLabel: function (key, label) {
      if (key !== null && typeof key !== "undefined" && label !== null && typeof label !== "undefined")
        return statics.filterLabels.filter((i) => i.key == key)[0][label];
      else return [];
    },
    getAccountType: function (
      key,
      value,
      label,
      labelWrap = "<span class='label-wrapper text-muted text-uppercase font-size-xs align-middle'>{0}</span>&nbsp;",
      valueWrap = "<span class='value-wrapper font-weight-bold'>{0}</span>"
    ) {
      if (typeof value !== "undefined" && value != null && label !== null && typeof label !== "undefined" && label != null)
        return labelWrap.format(vm.$gettext(statics.accountTypes[key][label])) + valueWrap.format(value);
      else if (typeof value !== "undefined" && value == null && label !== null && typeof label !== "undefined" && label != null)
        return labelWrap.format(vm.$gettext(statics.accountTypes[key][label]));
      else return [];
    },
    // Partner & widgets
    getMainRoleTypes: function (key) {
      if (key !== null && typeof key !== "undefined" && statics.partnerMainRoleTypes.filter((i) => i.id == key).length > 0)
        return statics.partnerMainRoleTypes.filter((i) => i.id == key);
      else return [];
    },
    getMainRoleType: function (key, label) {
      if (
        key !== null &&
        typeof key !== "undefined" &&
        label !== null &&
        typeof label !== "undefined" &&
        statics.partnerMainRoleTypes.filter((i) => i.id == key).length > 0
      )
        return statics.partnerMainRoleTypes.filter((i) => i.id == key)[0][label];
      else return ""; // Invalid prop: type check failed for prop "src". Expected String, got Array
    },
    // Pallets
    getPalletTypes: function (key) {
      try {
        if (key !== null && typeof key !== "undefined") return statics.palletTypes.filter((i) => i.id == key);
        else return [];
      } catch (e) {
        console.log("[ERROR]getPalletType::", e);
        return undefined;
      }
    },
    getPalletType: function (key, label) {
      try {
        if (key !== null && typeof key !== "undefined" && label !== null && typeof label !== "undefined")
          return statics.palletTypes.filter((i) => i.id == key)[0][label];
        else return [];
      } catch (e) {
        console.log("[ERROR]getPalletType::", e);
        return undefined;
      }
    },
    // Reports
    getStatType: function (key, label) {
      console.log("getStatType::", key, label);
      if (key !== null && typeof key !== "undefined" && label !== null && typeof label !== "undefined")
        return statics.statTypes.filter((t) => t.id == key)[0][label];
      else return [];
    },
  },
};
